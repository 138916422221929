import React from "react";

export const Footer = () => {
  return (
    <footer className="footer">
      <p>Built and designed by Maheer Huq.</p>
      <p>All rights reserved. ©</p>
    </footer>
  );
};
